import { MouseEvent, useRef } from 'react';

type PointerPosition = [x: number, y: number];

function eventToPosition(evt: MouseEvent): [x: number, y: number] {
  return [evt.screenX, evt.screenY];
}

export default function useTrackedPointer() {
  const lastPos = useRef<PointerPosition>([-1, -1]);

  return {
    wasMoved(evt: MouseEvent) {
      const newPos = eventToPosition(evt);
      if (lastPos.current[0] === newPos[0] && lastPos.current[1] === newPos[1])
        return false;
      lastPos.current = newPos;
      return true;
    },

    update(evt: MouseEvent) {
      lastPos.current = eventToPosition(evt);
    },
  };
}
