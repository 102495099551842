import { useMutation, useQueryClient } from '@tanstack/react-query';
import { GetCartQuery } from '@business/gql/graphql';
import { QueryKeys } from '@utils/queries/queryKeys';
import { getCookie, setCookie } from 'cookies-next';
import cartCookie from '@utils/cookies/cartCookie';
import { CartMutationArgs } from './useMutateCart';
import { addToCartAction } from '@actions/cart/actions';
import { MutationKeys } from '../../mutations/mutationKeys';

export default function useCartAdd(locale: string) {
  const query = useQueryClient();

  return useMutation({
    mutationKey: [MutationKeys.cart, locale],
    mutationFn: (payload: CartMutationArgs['items']) => {
      return addToCartAction({
        items: payload,
        locale,
        cartId: getCookie(cartCookie)?.toString() ?? '',
      });
    },
    onMutate: async () => {
      if (getCookie(cartCookie)) {
        await query.cancelQueries({ queryKey: [QueryKeys.cart, locale] });
        const previousCart = query.getQueryData([
          QueryKeys.cart,
          locale,
        ]) as GetCartQuery;
        return { previousCart };
      }
    },
    onSuccess: async (data) => {
      if (data.cart?.token && data.cart?.id) {
        setCookie(cartCookie, `${data.cart?.id}:${data.cart?.token}`);
      }
      query.setQueryData([QueryKeys.cart, locale], data);
    },
    onError: (_error, _variables, context) => {
      if (context?.previousCart) {
        query.setQueryData([QueryKeys.cart, locale], context.previousCart);
      }
    },
  });
}
