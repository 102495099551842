import { useMutation, useQueryClient } from '@tanstack/react-query';
import { QueryKeys } from '@utils/queries/queryKeys';
import { getCookie } from 'cookies-next';
import cartCookie from '@utils/cookies/cartCookie';
import { CartItemInput } from '@business/gql/graphql';
import { updateCartAction } from '@actions/cart/actions';
import { sendVoyadoCartChangeEvent } from '@utils/voyadoTracking';
import { MutationKeys } from '@utils/mutations/mutationKeys';

export default function useCartUpdate(locale: string) {
  const query = useQueryClient();
  return useMutation({
    mutationKey: [MutationKeys.cartUpdate, locale],
    mutationFn: async (cartItem: CartItemInput) => {
      try {
        const data =  await updateCartAction({
          cartItem,
          locale,
          cartId: getCookie(cartCookie)?.toString() ?? '',
        })
        if (!data) {
          throw new Error('useCartUpdate: no cart data')
        }
        return data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    onMutate: async () => {
      await query.cancelQueries({ queryKey: [QueryKeys.cart, locale] });

      const previousCart = query.getQueryData([QueryKeys.cart, locale]);

      return { previousCart };
    },
    onError: (_, _var, context) => {
      if (context?.previousCart) {
        query.setQueryData([QueryKeys.cart, locale], context.previousCart);
      }
    },
    onSuccess: async (data, _, context) => {
      if (data) {
        query.setQueryData([QueryKeys.cart, locale], data);
        try {
          sendVoyadoCartChangeEvent({ id: data.cart.id, items: data.cart.items });
        } catch (e) {}
      } else if (context?.previousCart) {
        query.setQueryData([QueryKeys.cart, locale], context?.previousCart);
      }
    },
  });
}
export type CartUpdateMutationFunc = ReturnType<typeof useCartUpdate>['mutate'];
