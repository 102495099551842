import { useQuery } from '@tanstack/react-query';
import { QueryKeys } from '@utils/queries/queryKeys';
import { getCookie } from 'cookies-next';
import cartCookie from '@utils/cookies/cartCookie';
import { getDeliveryAction } from '@app/actions/checkout/actions';
import type { Maybe } from 'graphql/jsutils/Maybe';
import type { CartFragment } from '@business/gql/graphql';
import { useMemo } from 'react';

type CartDeliveryProps = {
  cartItems?: Maybe<CartFragment['items']>;
  zipCode: string;
  locale: string;
};
export default function useCartDelivery({
  cartItems,
  zipCode,
  locale,
}: CartDeliveryProps) {
  const itemsCacheKey = useMemo(
    () =>
      cartItems?.reduce((acc: Record<string, string>, item) => {
        acc[item?.partNo] = String(item?.quantity);
        return acc;
      }, {}),
    [cartItems],
  );

  return useQuery({
    queryKey: [QueryKeys.delivery, zipCode, locale, itemsCacheKey],
    queryFn: () => {
      return getDeliveryAction({
        zipCode,
        locale,
        cartId: getCookie(cartCookie)?.toString() ?? '',
      });
    },
    staleTime: Infinity,
  });
}
