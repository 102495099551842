'use client';

import { ArrowDownIcon, ArrowUpIcon } from '@ngg/icons';
import { AnimatePresence, motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { usePathname } from 'next/navigation';
import { Maybe } from 'graphql/jsutils/Maybe';
import {
  ExceptionalOpeningHoursDay,
  OpeningHoursDay,
  StoreAndCustomerServiceInfoCollectionFragment,
} from '@business/gql/graphql';
import {
  getIrregularOpeningHoursDisplayData as getExceptionalOpeningHoursDisplayData,
  getOpeningHoursDisplayData,
  getOpeningHoursText,
} from '@utils/content/getOpeningHours';
import { getPhoneNumberForLink } from '@utils/content/getPhoneNumberForLink';
import useOutsideClick from '@utils/hooks/useOutsideClick';
import Icon from '../../icon/icon';
import { cn } from '@ngg/storefront-utils';
import { Button } from '@ngg/components';
import { useMenuStore } from '@app/_stores/menu.store';
import useDictionary from '@utils/hooks/useDictionary';
import Paragraph from '@ui/components/Paragraph/Paragraph';

type Props = {
  data: Maybe<StoreAndCustomerServiceInfoCollectionFragment>;
  translations: {
    customerSupport: string;
    openTodayAt: string;
    opensTomorrowAt: string;
    opensAtDayOfWeekAt: string;
    seeAllOpeningHours: string;
    closed: string;
    close: string;
  };
};

export default function CustomerServicePopup(props: Props) {
  const { data, translations } = props;
  const dictionary = useDictionary({
    keys: ['closed', 'irregularOpeningHours'],
  });
  const [popupOpen, setPopupOpen] = useState(false);
  const [openingHoursOpen, setOpeningHoursOpen] = useState<boolean>(false);
  const { topBarHeight } = useMenuStore();

  const customerSupportStore = data?.items.filter(
    (store) => store && store.type === 'Online',
  )?.[0];
  const openingHoursData = customerSupportStore?.openingHoursCollection
    ?.items as OpeningHoursDay[];
  const exceptionalOpeningHours = customerSupportStore
    ?.exceptionalOpeningHoursCollection?.items as
    | ExceptionalOpeningHoursDay[]
    | undefined;
  const exceptionalDisplayData = exceptionalOpeningHours
    ? getExceptionalOpeningHoursDisplayData(exceptionalOpeningHours, dictionary)
    : null;
  const displayData = getOpeningHoursDisplayData(
    openingHoursData,
    dictionary.closed,
  );
  const openingHoursText = getOpeningHoursText(
    new Date(),
    translations,
    openingHoursData,
    exceptionalOpeningHours,
  );
  const phoneNumber = customerSupportStore?.phoneNumber?.startsWith('+')
    ? getPhoneNumberForLink(customerSupportStore?.phoneNumber)?.slice(3)
    : getPhoneNumberForLink(customerSupportStore?.phoneNumber);

  const ref = useOutsideClick(() => setPopupOpen(false));
  const pathname = usePathname();

  useEffect(() => setPopupOpen(false), [pathname]);

  return (
    <div ref={ref}>
      <Button
        onClick={() => setPopupOpen(!popupOpen)}
        className={cn(
          'border-primary relative h-full gap-1 whitespace-nowrap border-x p-1 normal-case md:min-h-8',
          popupOpen
            ? 'border-borderColor bg-white text-black'
            : 'border-transparent bg-inherit',
        )}
        endIcon={
          popupOpen ? (
            <Icon name="upArrow" color="black" size={12} />
          ) : (
            <Icon name="downArrow" color="black" size={12} />
          )
        }>
        {translations?.customerSupport}
      </Button>

      <div
        style={{ top: `${topBarHeight}px` }}
        className={cn(
          'absolute right-0 z-50 max-w-[390px] border-x border-b bg-white p-6 normal-case text-black',
          popupOpen ? 'flex' : 'hidden',
        )}>
        <div className="flex w-full flex-col items-start justify-start">
          <Paragraph className="mb-6 whitespace-normal text-sm font-normal">
            {customerSupportStore?.infoText}
          </Paragraph>
          <Paragraph className="mb-1 text-sm">{openingHoursText}</Paragraph>
          <AnimatePresence>
            {openingHoursOpen && (
              <motion.div
                className="overflow-hidden"
                initial={{ height: 0 }}
                animate={{ height: 'auto' }}
                exit={{ height: 0 }}>
                {displayData?.map((item) => (
                  <Paragraph
                    key={
                      item?.text
                    }>{`${item?.text}: ${item?.time}`}</Paragraph>
                ))}
                {exceptionalDisplayData?.map((item) => (
                  <Paragraph
                    key={
                      item?.text
                    }>{`${item?.text}: ${item?.time}`}</Paragraph>
                ))}
              </motion.div>
            )}
          </AnimatePresence>
          <button
            type="button"
            onClick={() => setOpeningHoursOpen(!openingHoursOpen)}
            className={cn(
              'mb-3 inline-flex items-center gap-1 py-1 hover:underline',
            )}>
            {openingHoursOpen
              ? translations?.close
              : translations?.seeAllOpeningHours}
            {openingHoursOpen ? <ArrowUpIcon /> : <ArrowDownIcon />}
          </button>
          <div className="flex w-full flex-col space-y-2">
            {phoneNumber ? (
              <Button
                as="a"
                href={`tel:${phoneNumber}`}
                className={cn(
                  'btn-primary disabled min-h-8 grow border border-black bg-white tracking-wide text-black transition-colors',
                  'hover:border-black hover:bg-black hover:text-white hover:underline',
                )}>
                {customerSupportStore?.phoneNumber}
              </Button>
            ) : null}
            {customerSupportStore?.storePage && (
              <Button
                as="a"
                href={String(customerSupportStore.storePage.slug)}
                className={cn(
                  'btn-primary disabled min-h-8 grow border border-black bg-white tracking-wide text-black transition-colors',
                  'hover:border-black hover:bg-black hover:text-white hover:underline',
                )}>
                {customerSupportStore.storePageLinkText}
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
