import { useMutation, useQueryClient } from '@tanstack/react-query';
import { GetCartQuery } from '@business/gql/graphql';
import { QueryKeys } from '@utils/queries/queryKeys';
import cartCookie from '@utils/cookies/cartCookie';
import { getCookie } from 'cookies-next';
import { removeFromCartAction } from '@actions/cart/actions';
import { gTagRemoveFromCart } from '@utils/googleTags';
import { notifyVoyadoOfItemRemoval } from '@utils/voyadoTracking';
import { MutationKeys } from '@utils/mutations/mutationKeys';

function runCartTracking(partNo: string, cart: GetCartQuery['cart']) {
  try {
    notifyVoyadoOfItemRemoval(partNo, cart);
    gTagRemoveFromCart(partNo, cart);
  } catch (e) {
    console.group(
      console.error(
        'Something went wrong when sending cart tracking data during remove from cart.',
      ),
      console.error(e),
    );
  }
}

export default function useCartRemove(locale: string) {
  const query = useQueryClient();
  return useMutation({
    mutationKey: [MutationKeys.cartRemove, locale],
    mutationFn: (partNo: string) =>
      removeFromCartAction({
        partNo,
        locale,
        cartId: getCookie(cartCookie)?.toString() ?? '',
      }),
    onMutate: async (partNo: string) => {
      await query.cancelQueries({ queryKey: [QueryKeys.cart, locale] });
      const previousCart = query.getQueryData([
        QueryKeys.cart,
        locale,
      ]) as GetCartQuery;
      runCartTracking(partNo, previousCart.cart);
      return { previousCart };
    },
    onSuccess: (data) => {
      query.setQueryData([QueryKeys.cart, locale], { cart: data.cart });
    },
    onError: (_e, _v, context) => {
      if (context?.previousCart) {
        query.setQueryData([QueryKeys.cart, locale], {
          cart: context.previousCart,
        });
      }
    },
  });
}

export type CartRemoveMutationFunc = ReturnType<typeof useCartRemove>['mutate'];
