import config from './config';

export const getPhoneCountryCodeByMarket = () => {
  const { market } = config;
  if (market === 'se') {
    return '+46';
  }

  if (market === 'no') {
    return '+47';
  }

  return '';
};
