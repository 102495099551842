import { getPhoneCountryCodeByMarket } from '@utils/customerSupport';
import { Maybe } from 'graphql/jsutils/Maybe';

export function getPhoneNumberForLink(phoneNr: Maybe<string> | undefined) {
  if (!phoneNr) return undefined;
  const phoneCountryCode = getPhoneCountryCodeByMarket();
  const plainPhoneNumber = phoneNr.startsWith('0')
    ? phoneNr.replaceAll(' ', '').replace('-', '').slice(1)
    : phoneNr.replaceAll(' ', '').replace('-', '');

  return `${phoneCountryCode}${plainPhoneNumber}`;
}
