'use client';
import dynamic from 'next/dynamic';
import React, { useContext } from 'react';

export const LazyOpenDrawerPreloadProps = React.createContext({
  name: '',
  title: '',
});

const Loading = () => {
  const value = useContext(LazyOpenDrawerPreloadProps);
  return <div>{value.title}</div>;
};

export default dynamic(() => import('./OpenDrawerButton'), {
  ssr: false,
  loading: () => {
    return <Loading />;
  },
});
