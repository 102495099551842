import { create } from 'zustand';

type SearchStore = {
  searchFocus: boolean;
  setSearchFocus: (focus: boolean) => void;
  searchQuery: string;
  setSearchQuery: (query: string) => void;
};

export const useSearchStore = create<SearchStore>((set) => ({
  searchFocus: false,
  setSearchFocus: (focus: boolean) => set({ searchFocus: focus }),
  searchQuery: '',
  setSearchQuery: (query: string) => set({ searchQuery: query }),
}));
