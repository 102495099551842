import { HTMLProps } from 'react';
import { useSearch } from './Context';
import { useSearchStore } from '@utils/hooks/useSearchStore';
import { cn } from '@ngg/storefront-utils';

const SearchList = ({
  children,
  className,
  ...props
}: HTMLProps<HTMLUListElement>) => {
  const { selected } = useSearch();
  const searchQuery = useSearchStore((state) => state.searchQuery);
  const searchFocus = useSearchStore((state) => state.searchFocus);

  return (
    <ul
      data-active={selected}
      className={cn(
        'absolute left-0 top-full z-50 flex min-w-[calc(100%+2px)] -translate-x-[1px]  flex-col bg-inherit',
        (!searchQuery || !searchFocus) && 'hidden',
        className,
      )}
      {...props}>
      {children}
    </ul>
  );
};

SearchList.displayName = 'SearchList';

export default SearchList;
