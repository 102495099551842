'use client';

import { useCallback, useEffect, useState } from 'react';

export type ScrollDirection = 'up' | 'down';

const useScroll = () => {
  const delta = 5; // Delta value to determine scroll direction change sensitivity
  const [ticking, setTicking] = useState(false);
  const [scrollDirection, setScrollDirection] = useState<ScrollDirection>('up');
  const [prevOffset, setPrevOffset] = useState(0);

  const handleScrollDirection = useCallback(() => {
    const scrollY = window.scrollY;
    const scrollDelta = scrollY - prevOffset;

    if (scrollDelta > delta) {
      setScrollDirection('down');
    } else if (scrollDelta < -delta) {
      setScrollDirection('up');
    }

    if (!ticking) {
      requestAnimationFrame(() => {
        setTicking(false);
        setPrevOffset(scrollY);
      });
    }

    setTicking(true);
  }, [prevOffset, ticking, setTicking, setPrevOffset, setScrollDirection]);

  useEffect(() => {
    window.addEventListener('scroll', handleScrollDirection);

    return () => {
      window.removeEventListener('scroll', handleScrollDirection);
    };
  }, [handleScrollDirection]);

  return {
    scrollDirection,
    scrollPosition: prevOffset,
  };
};

export default useScroll;
