'use client';

import { useLayoutEffect, useState } from 'react';
import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from 'tailwind.config';
import debounce from 'lodash.debounce';

const fullConfig = resolveConfig(tailwindConfig) as any;

type TWindowSize = {
    width: number;
    height: number;
};
type Breakpoint = 'sm' | 'md' | 'lg' | 'xl' | '2xl';

const checkBreakpoint = (windowSize: TWindowSize, width?: Breakpoint, maxWidth?: Breakpoint) => {
    if (width && maxWidth) {
        return (
            (windowSize.width >= parseInt(fullConfig?.theme?.screens?.[width], 10) &&
                windowSize.width < parseInt(fullConfig?.theme?.screens?.[width], 10)) ||
            false
        );
    }
    if (width) {
        return windowSize.width >= parseInt(fullConfig?.theme?.screens?.[width], 10) || false;
    }
    if (maxWidth) {
        return windowSize.width < parseInt(fullConfig?.theme?.screens?.[maxWidth], 10) || false;
    }

    return false;
};

/**
 * Returns the window size and updates it on resize.
 * @param width - The tailwind min width of the window.
 * @param maxWidth - The tailwind max width of the window.
 * @returns The window size and if breakpoint is met
 *  */
export default function useWindowSize(width?: Breakpoint, maxWidth?: Breakpoint, debounceWait = 250) {
    const [windowSize, setWindowSize] = useState({ width: 0, height: 0 });

    useLayoutEffect(() => {
        if (typeof window === 'undefined') return undefined;

        const handleResize = debounce(() => {
            setWindowSize({ width: window.innerWidth, height: window.innerHeight });
        }, debounceWait);
        setWindowSize({ width: window.innerWidth, height: window.innerHeight });

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [debounceWait]);

    return {
        width: windowSize.width,
        height: windowSize.height,
        isBreakpoint: checkBreakpoint(windowSize, width, maxWidth),
    };
}
