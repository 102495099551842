import { tv } from 'tailwind-variants';

export const desktopImageContainer = tv({
  base: '',
  variants: {
    size: {
      mobile: 'max-sm:hidden',
      default: '',
    },
  },
});
export const mobileImageContainer = tv({
  base: '',
  variants: {
    size: {
      mobile: 'sm:hidden',
      default: '',
    },
  },
});
