'use client';

import { setCookie } from 'cookies-next';
import { useCallback, useState } from 'react';
import type { CampaignBarFragment, Maybe } from '@business/gql/graphql';
import { cn } from '@ngg/storefront-utils';
import config from '@utils/config';
import Icon from '../icon/icon';
import RichTextLink from '../RichTextLink/RichtTextLink';

const getCampaignBarBackgroundColor = (color?: string) => {
  if (color === 'red') {
    return 'bg-yellow text-yellow-contrast';
  }

  if (color === 'green') {
    return 'bg-green text-green-contrast';
  }

  return 'bg-primary-yellow text-yellow-contrast';
};

type Props = {
  campaignBar?: Maybe<CampaignBarFragment>;
  hasCampaignBarCookie: boolean;
};

export default function CampaignBar(props: Props) {
  const { locale } = config;
  const { campaignBar: campaignBarData, hasCampaignBarCookie } = props;

  const [campaignBarCookie, setCampaignBarCookie] =
    useState(hasCampaignBarCookie);

  const onHide = useCallback(() => {
    setCookie('lm_campaign_bar', true);
    setCampaignBarCookie(true);
  }, []);

  const bgColor = getCampaignBarBackgroundColor(
    campaignBarData?.backgroundColor ?? '',
  );

  // For now we should only show one item in campaign bar
  const campaign = campaignBarData?.navigationLinksCollection?.items[0];

  if (!campaign || !campaign?.title || campaignBarCookie) {
    return null;
  }

  return (
    <div
      id="campaign-bar"
      className={cn(
        'relative flex items-center justify-center overflow-hidden',
        campaignBarData?.backgroundColor && bgColor,
      )}>
      <RichTextLink
        className="flex w-full flex-1 flex-row flex-wrap items-center justify-center text-center text-xs font-medium lg:hover:underline"
        locale={locale}
        href={campaign?.externalLink ?? campaign?.internalLink?.slug ?? ''}>
        <p className="py-1.5 pl-3 leading-snug">
          {campaign?.title}{' '}
          <Icon
            name="rightArrowWithTail"
            className="mb-0.5 ml-1 inline-block"
            size={12}
          />
        </p>
      </RichTextLink>
      <button
        type="button"
        className="right-0 flex flex-shrink-0 place-content-center place-items-center p-2.5"
        onClick={onHide}>
        <Icon name="cross" className="h-3 w-3" />
      </button>
    </div>
  );
}
