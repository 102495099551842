import { useQuery } from '@tanstack/react-query';
import { QueryKeys } from '@utils/queries/queryKeys';
import { deleteCookie, getCookie } from 'cookies-next';
import cartCookie from '@utils/cookies/cartCookie';
import { getCartAction } from '@actions/cart/actions';

export default function useCart(locale: string) {
  return useQuery({
    queryKey: [QueryKeys.cart, locale],
    queryFn: () =>
      getCartAction({
        locale,
        cartId: getCookie(cartCookie)?.toString() ?? '',
      }).then((res) => {
        if (res?.cart === null) {
          deleteCookie(cartCookie);
        }
        return res;
      }),
    staleTime: Infinity,
  });
}
