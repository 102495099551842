type AutoCompleteResult = {
  brands: Array<Record<string, string>>;
  categories: Array<Record<string, string>>;
  designers: Array<Record<string, string>>;
};

export function findAutocompleteMatch({
  data,
  query,
}: {
  data?: AutoCompleteResult | null;
  query: string;
}) {
  const matches = [
    ...(data?.brands ?? []),
    ...(data?.designers ?? []),
    ...(data?.categories ?? []),
  ];

  const match = matches.find(
    (item) => item?.contentName.toLowerCase() === query.toLowerCase(),
  );

  return match;
}
