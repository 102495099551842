import { locale } from '../config';

export function getSearchPathname() {
  switch (locale?.toLowerCase()) {
    case 'sv-se':
      return '/sok';
    case 'nb-no':
      return '/sog';
    default:
      return 'search';
  }
}
