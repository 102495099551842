import {
  ExceptionalOpeningHoursDay,
  Maybe,
  OpeningHoursDay,
} from '@business/gql/graphql';
import { Dictionary } from '@ts/dictionary';

type OpeningHour = {
  days: string[];
  start: string;
  end: string;
};

export function getOpeningHours(initialData: OpeningHoursDay[]) {
  const hours = initialData.reduce((a, b): OpeningHour[] => {
    if (b.closed) return a;
    if (a.length) {
      const prevDay = a.pop();
      if (
        prevDay &&
        prevDay.start === b.openingTime &&
        prevDay.end === b.closingTime
      ) {
        a.push({
          ...prevDay,
          days: [...prevDay.days, b.dayOfTheWeek as string],
        });
        return a;
      } else if (prevDay) {
        a.push(prevDay);
      }
    }
    return [
      ...a,
      {
        days: [b.dayOfTheWeek as string],
        start: b.openingTime as string,
        end: b.closingTime as string,
      },
    ];
  }, [] as OpeningHour[]);
  return hours;
}

export function getOpeningHoursDisplayData(
  initialData: Pick<
    OpeningHoursDay,
    'closed' | 'closingTime' | 'dayOfTheWeek' | 'openingTime'
  >[],
  closedTitle: string,
) {
  const days: { text: string; time: string }[] = [];
  const data = initialData?.map((i) => {
    const item = i;
    if (item.closed) {
      item.openingTime = undefined;
      item.closingTime = undefined;
    }
    return item;
  });

  let text = '';
  let i = 0;

  if (!data) return days;

  while (i < data.length) {
    const day = data[i];
    const nextDay = data[i + 1];
    const dayClosed = Boolean(day.closed);
    const sameTime =
      day.openingTime === nextDay?.openingTime &&
      day.closingTime === nextDay?.closingTime &&
      day.closed === nextDay?.closed;

    if (!sameTime && !dayClosed) {
      days.push({
        text: day.dayOfTheWeek || '',
        time: `${day.openingTime} - ${day.closingTime}`,
      });
      i += 1;
    } else if (!sameTime && dayClosed) {
      days.push({
        text: day.dayOfTheWeek || '',
        time: closedTitle,
      });
      i += 1;
    } else {
      const localeIndex = i;
      text = (day.dayOfTheWeek as string).slice(0, 3);
      const last = data.findIndex(
        (d, j) =>
          j > localeIndex &&
          (day.openingTime !== d.openingTime ||
            day.closingTime !== d.closingTime),
      );
      const lastSameTimeIndex = last === -1 ? data.length - 1 : last - 1;
      text += dayClosed
        ? `-${data[lastSameTimeIndex].dayOfTheWeek?.slice(0, 3)}`
        : `-${data[lastSameTimeIndex].dayOfTheWeek?.slice(0, 3)}`;
      days.push({
        text: `${text}`,
        time: dayClosed
          ? closedTitle
          : `${day.openingTime} - ${day.closingTime}`,
      });
      i = lastSameTimeIndex + 1;
    }
  }
  return days;
}

function getDaysBetweenDates({
  dateNow,
  inputDate,
}: {
  dateNow: Date;
  inputDate: Date;
}) {
  const oneDayMs = 1000 * 60 * 60 * 24;
  const dateNowMs = dateNow.getTime();
  const inputDateMs = inputDate.getTime();
  const differenceMs = inputDateMs - dateNowMs;
  return Math.ceil(differenceMs / oneDayMs);
}

export function getIrregularOpeningHoursDisplayData(
  data: ExceptionalOpeningHoursDay[] | undefined,
  dictionary: Record<string, string>,
): { text: string; time: string }[] {
  try {
    if (!data) {
      return [];
    }
    const days: { text: string; time: string }[] = [];
    const dateNow = new Date();

    for (let i = 0; i < data?.length; i += 1) {
      const day = data[i];
      if (!day || !day.date) {
        continue;
      }
      const inputDate = new Date(day?.date);
      const differenceInDays = getDaysBetweenDates({ dateNow, inputDate });
      if (differenceInDays > -1 && differenceInDays < 8) {
        days.push({
          text: day.title || '',
          time: day.closed
            ? `${dictionary?.closed}`
            : `${day.openingTime} - ${day.closingTime}`,
        });
      }
    }
    return days;
  } catch (error) {
    console.error(
      'Error when getting irregular opening hours display data: ',
      error,
    );
    return [];
  }
}

type OpeningHours = Pick<
  OpeningHoursDay,
  'closed' | 'openingTime' | 'closingTime' | 'dayOfTheWeek'
>;

type ExceptionalOpeningHours = Pick<
  ExceptionalOpeningHoursDay,
  'closed' | 'openingTime' | 'closingTime' | 'date'
>;

export function getOpeningHoursText(
  currentTime: Date,
  dictionary?: Dictionary,
  regularOpeningHours?: OpeningHours[],
  exceptionalOpeningHours?: ExceptionalOpeningHours[],
) {
  try {
    if (!dictionary || !regularOpeningHours) return undefined;

    let text = '';
    const todaysDayIndex = getDayIndexMonToSun(currentTime);
    const todaysHours = regularOpeningHours?.[todaysDayIndex];
    const exceptionalToday = findExceptionalMatchingDate(
      currentTime,
      exceptionalOpeningHours,
    );
    const hourNow = currentTime.getHours();
    const todaysOpeningHour = getTodaysOpeningHour(
      todaysHours,
      exceptionalToday,
    );
    const todaysClosingHour = getTodaysClosingHour(
      todaysHours,
      exceptionalToday,
    );
    const nextDayOpen = getNextDayOpenRecursive(
      10,
      currentTime,
      regularOpeningHours,
      exceptionalOpeningHours,
    );
    const isOpenTomorrow = getIsOpenTomorrow(
      currentTime,
      regularOpeningHours,
      exceptionalOpeningHours,
    );
    const nextDayOpeningHour = nextDayOpen?.openingTime?.slice(
      0,
      nextDayOpen?.openingTime?.indexOf(':'),
    );

    if (
      todaysOpeningHour &&
      todaysClosingHour &&
      hourNow <= parseInt(todaysClosingHour, 10)
    ) {
      text = `${dictionary?.openTodayAt} ${todaysOpeningHour}-${todaysClosingHour}`;
    } else if (isOpenTomorrow) {
      text = `${dictionary?.opensTomorrowAt} ${nextDayOpeningHour}`;
    } else {
      text = `${dictionary.opensAtDayOfWeekAt.replace(
        '%DAYOFTHEWEEK%',
        `${nextDayOpen?.dayOfTheWeek?.toLocaleLowerCase()}`,
      )} ${nextDayOpeningHour}`;
    }
    return text;
  } catch (error) {
    console.error('Error when getting opening hours text: ' + error);
    undefined;
  }
}

function findExceptionalMatchingDate(
  dateToMatch: Date,
  exceptionalHours?: ExceptionalOpeningHours[],
): ExceptionalOpeningHours | undefined {
  if (!exceptionalHours) {
    return undefined;
  }
  const dateString = dateToMatch.toLocaleDateString();
  for (const openingHour of exceptionalHours) {
    if (!openingHour || !openingHour?.date) {
      continue;
    }
    const exceptionalDate = new Date(openingHour.date);
    if (exceptionalDate.toLocaleDateString() === dateString) {
      return openingHour;
    }
  }
  return undefined;
}

function getTodaysOpeningHour(
  regularToday: OpeningHours,
  exceptionalToday?: ExceptionalOpeningHours,
) {
  if (exceptionalToday) {
    return exceptionalToday?.closed
      ? undefined
      : exceptionalToday?.openingTime?.slice(
          0,
          exceptionalToday?.openingTime?.indexOf(':'),
        );
  }
  return regularToday?.closed
    ? undefined
    : regularToday?.openingTime?.slice(
        0,
        regularToday?.openingTime?.indexOf(':'),
      );
}

function getTodaysClosingHour(
  regularToday: OpeningHours,
  exceptionalToday?: ExceptionalOpeningHours,
) {
  if (exceptionalToday) {
    return exceptionalToday?.closed
      ? undefined
      : exceptionalToday?.closingTime?.slice(
          0,
          exceptionalToday?.closingTime?.indexOf(':'),
        );
  }
  return regularToday?.closed
    ? undefined
    : regularToday?.closingTime?.slice(
        0,
        regularToday?.closingTime?.indexOf(':'),
      );
}

/**
 * Gets the next day open from set of openingHours.
 * @param dayIndex - the array index to start looking from, 0-6, mon-sun
 * @param openingHours - the array to look in
 */
function getNextRegularDayOpen(dayIndex: number, openingHours: OpeningHours[]) {
  return dayIndex === 6
    ? openingHours?.find((item) => !item.closed)
    : openingHours?.find((item, index) => !item.closed && index > dayIndex) ??
        openingHours?.find((item) => !item.closed);
}

function addDays(date: Date, days: number) {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

function getNextDayOpenRecursive(
  maxIterations: number,
  dateToLookFromNonInclusive: Date,
  regularHours: OpeningHours[],
  exceptionalHours?: ExceptionalOpeningHours[],
) {
  if (maxIterations <= 0) {
    return undefined;
  }
  const dayIndex = getDayIndexMonToSun(dateToLookFromNonInclusive);
  const nextRegularDayOpen = getNextRegularDayOpen(dayIndex, regularHours);
  if (!nextRegularDayOpen) {
    return undefined;
  }
  // regularHours are in order and of length 7
  const foundIndex = regularHours.indexOf(nextRegularDayOpen);
  const dayDiff =
    foundIndex >= dayIndex ? foundIndex - dayIndex : foundIndex + 6 - dayIndex;
  const foundDate = addDays(dateToLookFromNonInclusive, dayDiff);
  const matchingExceptional = findExceptionalMatchingDate(
    foundDate,
    exceptionalHours,
  );
  if (matchingExceptional) {
    if (matchingExceptional.closed) {
      return getNextDayOpenRecursive(
        maxIterations - 1,
        foundDate,
        regularHours,
        exceptionalHours,
      );
    }
    return {
      ...matchingExceptional,
      dayOfTheWeek: nextRegularDayOpen.dayOfTheWeek,
    };
  }
  return nextRegularDayOpen;
}

function getDayIndexMonToSun(date: Date): number {
  const dayOfWeekSunToSat = date.getDay();
  return dayOfWeekSunToSat === 0 ? 6 : dayOfWeekSunToSat - 1;
}

function getIsOpenTomorrow(
  currentTime: Date,
  regularHours: OpeningHours[],
  exceptionalHours?: ExceptionalOpeningHours[],
): boolean {
  const tomorrow = addDays(currentTime, 1);
  const tomorrowsDayIndex = getDayIndexMonToSun(tomorrow);
  const tomorrowRegularHours = regularHours[tomorrowsDayIndex];
  const tomorrowExceptionalHours = findExceptionalMatchingDate(
    tomorrow,
    exceptionalHours,
  );
  if (tomorrowExceptionalHours && tomorrowExceptionalHours.closed) {
    return false;
  }
  return !tomorrowRegularHours.closed;
}
