'use client';

import React from 'react';
import Image from '@ui/components/Image/Image';

type Props = {
  imageKey: string;
  alt?: string;
};

export default function CartImage(props: Props) {
  const { imageKey, alt } = props;
  return (
    <Image
      width={72}
      height={72}
      className="h-full object-contain mix-blend-multiply"
      src={`${process.env.NEXT_PUBLIC_NORCE_IMAGE_CDN}/${imageKey}?w=200&h=200&mode=max`}
      alt={alt ?? ''}
    />
  );
}
