import { tv } from 'tailwind-variants';

export const searchVariants = tv({
  slots: {
    searchComponent:
      'static w-full min-w-[300px] border border-borderColor lg:relative',
    listContainer:
      ' max-h-[calc(100dvh-6rem)] border-gray-150 w-full overflow-y-auto overflow-x-hidden md:border md:border-x-borderColor bg-white text-xs',
    suggestionContainer: 'border-b py-3',
    heading: 'px-9 py-1 text-xxs font-bold uppercase',
    suggestion: 'px-9 py-1',
  },
});

export default searchVariants;
