export default function getContentfulLocaleFromLanguage(str?: string) {
  switch (str) {
    case 'nb':
    case 'nb-NO':
    case 'nb-no':
    case 'no':
      return 'nb-NO';
    case 'sv':
    case 'sv-SE':
    case 'sv-se':
    case 'se':
    default:
      return 'sv-SE';
  }
}

export function getContentfulActiveMarketFromLanguage(str?: string) {
  switch (str) {
    case 'nb':
    case 'nb-NO':
    case 'nb-no':
    case 'no':
      return 'Norway';
    case 'sv':
    case 'sv-SE':
    case 'sv-se':
    case 'se':
    default:
      return 'Sweden';
  }
}
