import { getDictionaryAction } from '@app/actions/dictionary/actions';
import { useQuery } from '@tanstack/react-query';
import config from '@utils/config';
import { QueryKeys } from '@utils/queries/queryKeys';
import { useMemo } from 'react';

type DictionaryReturn<T extends string> = { [K in T]: string };

export default function useDictionary<T extends string>(props: { keys: T[] }) {
  const { keys } = props;

  const data = useQuery({
    queryKey: [QueryKeys.dictionary, config.locale, keys.join('-')],
    queryFn: () => getDictionaryAction({ locale: config.locale, keys }),
  });

  return useMemo(() => {
    if (!data.data) {
      return keys.reduce((acc, key) => {
        return {
          ...acc,
          [key]: key,
        };
      }, {}) as DictionaryReturn<T>;
    }
    return Object.fromEntries(data.data) as DictionaryReturn<T>;
  }, [data.data, keys]);
}
export type UseDictionary = ReturnType<typeof useDictionary>;
