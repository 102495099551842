'use client';

import { useMenuStore } from '@stores/menu.store';
import useScroll from '@utils/useScroll';
import useWindowSize from '@utils/useWindowSize';
import { ReactNode, useEffect, useMemo } from 'react';
import Search from '../../search/search';
import { cn } from '@ngg/storefront-utils';
import { Dictionary } from '@ts/dictionary';
import CampaignBar from '../../CampaignBar/CampaignBar';
import { Maybe } from 'graphql/jsutils/Maybe';
import { CampaignBarFragment } from '@business/gql/graphql';
import config from '@utils/config';

const MOBILE_USP_BAR_HEIGHT = 24;
const DESKTOP_USP_BAR_HEIGHT = 32;

export default function HeaderWrapper({
  campaignBar,
  hasCampaignBarCookie,
  children,
  isCheckout,
}: {
  campaignBar?: Maybe<CampaignBarFragment>;
  hasCampaignBarCookie: boolean;
  children: ReactNode;
  isCheckout?: boolean;
}) {
  const { locale } = config;
  const {
    topBarVisible,
    setTopBarVisible,
    setTopBarHeight,
    setShowSearchAndCampaignBar,
    showSearchAndCampaignBar,
    setShowSearchIcon,
    drawerIsOpen,
  } = useMenuStore();
  const { width: windowWidth } = useWindowSize('lg');
  const { scrollPosition, scrollDirection } = useScroll();
  const hasScrolled = scrollPosition > 150;
  const uspBarHeight = useMemo(
    () => (windowWidth > 769 ? DESKTOP_USP_BAR_HEIGHT : MOBILE_USP_BAR_HEIGHT),
    [windowWidth],
  );

  useEffect(() => {
    setTopBarHeight(uspBarHeight);
  }, [uspBarHeight, setTopBarHeight]);

  useEffect(() => {
    if (hasScrolled && scrollDirection === 'up') {
      setShowSearchAndCampaignBar(true);
      setShowSearchIcon(false);
      setTopBarVisible(true);
    }
    if (hasScrolled && scrollDirection === 'down') {
      setShowSearchAndCampaignBar(false);
      setShowSearchIcon(true);
      setTopBarVisible(false);
    }
  }, [
    hasScrolled,
    scrollDirection,
    setShowSearchAndCampaignBar,
    setShowSearchIcon,
    setTopBarVisible,
  ]);

  useEffect(() => {
    if (scrollPosition === 0 && !drawerIsOpen) {
      setShowSearchAndCampaignBar(true);
      setShowSearchIcon(false);
      setTopBarVisible(true);
    }
    // Because of how the menu drawers are implemented we have a state of scrollPosition === 0 and mobileMenuOpen === false while the menu is closing.
    // To get around laggy behaviour I opted to ignore the mobileMenuOpen dependency here.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollPosition, setShowSearchAndCampaignBar]);

  return (
    <header
      style={
        {
          '--header-content-top':
            !topBarVisible && !isCheckout ? `${uspBarHeight}px` : '0px',
        } as React.CSSProperties
      }
      id="navbar"
      className="sticky top-0 z-20 -translate-y-[--header-content-top] transition-transform duration-200">
      {children}
      <div className="h-px w-full bg-beige-3" />
      {!isCheckout && (
        <div
          className={cn(
            'transition-transform duration-300 ',
            !showSearchAndCampaignBar && '-translate-y-[105%]',
          )}>
          <div data-testid="Search-mobile" className="lg:hidden">
            <Search
              locale={locale}
              classNames={{
                root: cn(
                  'z-1 relative border-b border-t-0 border-x-0 border-borderColor ',
                ),
                inputContainer: 'h-[40px] px-4',
                input:
                  'placeholder:text-sm placeholder:text-gray-600 text-base border-none',
                icon: 'left-4 h-6',
              }}
            />
          </div>
          <CampaignBar
            campaignBar={campaignBar}
            hasCampaignBarCookie={hasCampaignBarCookie}
          />
        </div>
      )}
    </header>
  );
}
