import { useQuery } from '@tanstack/react-query';
import { QueryKeys } from '../queries/queryKeys';
import fetchAutocomplete from '@business/product/fetchAutocomplete';

type Props = {
  query: string;
  locale: string;
};

export default function useAutocomplete({ query, locale }: Props) {
  return useQuery({
    queryKey: [QueryKeys.autocomplete, query, locale],
    queryFn: () => {
      return fetchAutocomplete({
        query,
        market: locale,
      });
    },
    staleTime: 1000 * 30, // 30s
    gcTime: 1000 * 60 * 15, // 15m
    enabled: typeof query === 'string' && query.length >= 1,
  });
}
