import debounce from 'lodash.debounce';
import { useCallback, useMemo, useRef, useState } from 'react';
import { CartUpdateMutationFunc } from './cart/useCartUpdate';
import { CartRemoveMutationFunc } from './cart/useCartRemove';
import { Cart } from '@business/gql/graphql';

interface UpdateDebounced<T extends (...args: any[]) => any> {
  /**
   * Call the original function, but applying the debounce rules.
   *
   * If the debounced function can be run immediately, this calls it and returns its return
   * value.
   *
   * Otherwise, it returns the return value of the last invocation, or undefined if the debounced
   * function was not invoked yet.
   */
  (...args: Parameters<T>): ReturnType<T> | undefined;

  /**
   * Throw away any pending invocation of the debounced function.
   */
  cancel(): void;

  /**
   * If there is a pending invocation of the debounced function, invoke it immediately and return
   * its return value.
   *
   * Otherwise, return the value from the last invocation, or undefined if the debounced function
   * was never invoked.
   */
  flush(): ReturnType<T> | undefined;
}

type Props = {
  quantity: number;
  partNo: string;
  update: CartUpdateMutationFunc;
  remove: CartRemoveMutationFunc;
  onQuantityChange?: (quantity: number, id: string) => void;
  id?: string;
};
export default function useSetProductQuantity({
  quantity,
  partNo,
  update,
  remove,
  onQuantityChange,
  id,
}: Props) {
  const updateCartRef = useRef<UpdateDebounced<(quant: number) => void>>();
  const [stateQuantity, setQuantity] = useState(quantity);
  const updateQuantity = useMemo(() => {
    updateCartRef.current = debounce((quant: number) => {
      update({ partNo, quantity: quant }, {
        onError: (error, _, context) => {
          const previousCart = context?.previousCart as { cart: Cart } | undefined
          const previousQuantity = previousCart?.cart?.items?.find(({ partNo }) => partNo)?.quantity;

          if (previousQuantity) {
            setQuantity(previousQuantity)
          }
        },
      });
    }, 400);
    return updateCartRef.current;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partNo, update]);

  const increase = useCallback(() => {
    const newQuantity = stateQuantity + 1;
    setQuantity(newQuantity);
    if (id) {
      onQuantityChange?.(newQuantity, id);
    }
    updateQuantity?.(newQuantity);
  }, [
    id,
    stateQuantity,
    updateQuantity,
    onQuantityChange,
  ]);

  const decrease = useCallback(() => {
    if (stateQuantity === 1) {
      updateCartRef.current?.cancel();
      remove(partNo);
      return;
    }

    const newQuantity = stateQuantity - 1;

    if (id) {
      onQuantityChange?.(newQuantity, id);
    }
    setQuantity(newQuantity);
    updateQuantity(newQuantity);
  }, [
    id,
    partNo,
    remove,
    stateQuantity,
    updateQuantity,
    onQuantityChange,
  ]);

  return {
    increase,
    decrease,
    quantity: stateQuantity,
  };
}
