import { Fragment, ReactNode } from 'react';
import { Transition } from '@headlessui/react';
import { cn } from '@ngg/storefront-utils';

export default function Badge({
  children,
  badgeContent = 0,
  showZero = false,
  className = '',
}: {
  children: ReactNode;
  badgeContent: number | ReactNode | null;
  showZero?: boolean;
  className?: string;
}) {
  if (typeof badgeContent === 'number') {
    return (
      <div
        className="relative flex h-full items-center"
        data-testid="badge-content-number">
        <Transition
          show={!showZero && badgeContent > 0}
          key={badgeContent}
          as={Fragment}
          appear
          entered="before:animate-[cart-animation_1.5s_ease]">
          <span
            className={cn(
              'flex-center absolute -right-1 -top-1 flex h-4 w-4 items-center justify-center rounded-full text-center text-xxs tabular-nums leading-none text-black before:absolute before:inset-0 before:block before:rounded-full before:bg-yellow',
              className,
            )}>
            <span className="z-10 animate-none leading-[0]">
              {badgeContent}
            </span>
          </span>
        </Transition>
        {children}
      </div>
    );
  }
  return (
    <div className="relative flex h-full items-center">
      {badgeContent !== null ? (
        <span
          className={cn(
            'flex-center absolute -right-1 -top-1 flex h-4 w-4 rounded-full',
            className,
          )}>
          {badgeContent}
        </span>
      ) : null}
      {children}
    </div>
  );
}
