import LinkWithLocale from '@ui/components/Link/Link';
import Link from 'next/link';
import type { HTMLAttributes } from 'react';

type RichTextLinkProps = HTMLAttributes<HTMLAnchorElement> & {
  locale?: string;
  href: string;
};

const RichTextLink = ({
  children,
  href,
  locale,
  className,
  ...props
}: RichTextLinkProps) => {
  const isExternalLink =
    href.includes('lannamobler.se') === false &&
    href.includes('lanna.no') === false;

  const isAbsoluteLink = href.includes('http') === true;

  if (isExternalLink && isAbsoluteLink) {
    return (
      <a
        href={href}
        className={className}
        target="_blank"
        rel="noopener noreferrer"
        {...props}>
        {children}
      </a>
    );
  }

  if (locale) {
    <LinkWithLocale className={className} locale={locale} href={href} />;
  }

  return (
    <Link className={className} href={href}>
      {children}
    </Link>
  );
};

RichTextLink.displayName = 'RichTextLink';

export default RichTextLink;
