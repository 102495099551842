export enum QueryKeys {
  cart = 'cart',
  delivery = 'delivery',
  article = 'article',
  articleWithSlug = 'articleWithSlug',
  checkout = 'checkout',
  campaignProducts = 'campaignProducts',
  navigationLinks = 'navigationLinks',
  productInfo = 'productInfo',
  getPage = 'getPage',
  getGenericPage = 'getGenericPage',
  loopSearch = 'loopSearch',
  product = 'product',
  lightsources = 'lightsources',
  pages = 'pages',
  getProductByAttribute = 'getProductByAttribute',
  checkPaymentSuccessful = 'checkPaymentSuccessful',
  categorySiteInfo = 'categorySiteInfo',
  siteInfo = 'siteInfo',
  page = 'page',
  editorialPageSlugs = 'editorialPageSlugs',
  getPageSlugs = 'getPageSlugs',
  getDesignerPage = 'getDesignerPage',
  getBrandPage = 'getBrandPage',
  getBreadcrumbs = 'getBreadcrumbs',
  getProductCategory = 'getProductCategory',
  infinityProducts = 'infinityProducts',
  infinityArticleLoader = 'inifinityArticleLoader',
  getCategoriesPage = 'getCategoriesPage',
  getMagazinesPage = 'getMagazinesPage',
  getCategoryProducts = 'getCategoryProducts',
  searchPage = 'searchPage',
  autocomplete = 'autocomplete',
  stores = 'stores',
  variant = 'variant',
  dictionary = 'dictionary',
  getNavigationLinksIdByName = 'getNavigationLinksIdByName',
}
export type QueryKeysType = keyof typeof QueryKeys;
