import { IconProps } from '../icon.types';
import Svg from '../assets/hamburger.svg';

export default function Hamburger({
  color,
  className,
  size = 16,
  ...rest
}: IconProps) {
  return (
    <Svg
      {...rest}
      color={color}
      width={size}
      height={size}
      className={className}
    />
  );
}
