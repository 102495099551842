'use client';
import { cn } from '@ngg/storefront-utils';
import { Maybe } from 'graphql/jsutils/Maybe';
import { NavigationFragment } from '@business/gql/graphql';
import { useMemo, useState } from 'react';
import { usePathname } from 'next/navigation';
import Link from '../Link/Link';
import sortBy from 'lodash.sortby';
import LazyOpenDrawerButton, {
  LazyOpenDrawerPreloadProps,
} from './components/LazyOpenDrawerButton';
import LazyMenuDrawer from './components/LazyMenuDrawer';
import LazyMobileMenu from '../MobileMenu/LazyMobileMenu';
import Hamburger from '../icon/OptimizedIcon/Hamburger';

enum Order {
  News,
  Campaigns,
  Brands,
  Magazines,
  Categories,
}

type Props = {
  locale: string;
  drawerElements: Maybe<NavigationFragment>[];
  productsElement: NavigationFragment | undefined | null;
  campaignsElement: NavigationFragment | null;
  newsElement: NavigationFragment | null;
  remainingNavigationElements:
    | Array<NavigationFragment | null>
    | undefined
    | null;
};

export default function MainNavigation(props: Props) {
  const pathname = usePathname();
  const [activeNavigationButton, setActiveNavigationButton] = useState('');

  const [desktopNavigationDrawerOpen, setDesktopNavigationDrawerOpen] =
    useState(false);

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const drawerElement = props.drawerElements?.[0];
  const activeNavigationElement = useMemo(() => {
    const value = props.drawerElements?.find(
      (drawerElement) => drawerElement?.name === activeNavigationButton,
    );
    if (!value) return props.productsElement?.name ?? 'Categories';
    return value?.name;
  }, [activeNavigationButton, props.drawerElements, props.productsElement]);

  const sortedRemainingNavigationElements = useMemo(() => {
    return sortBy(
      props.remainingNavigationElements,
      (navigation) =>
        navigation?.name && Order[navigation?.name as keyof typeof Order],
    );
  }, [props.remainingNavigationElements]);

  return (
    <>
      <div className="flex h-full flex-1 items-center text-sm uppercase">
        <nav className="z-30" role="navigation">
          <div className="flex gap-2">
            <button
              data-testid="Mobile-nav-button"
              className="cursor-pointer p-4 lg:hidden"
              onClick={() => {
                setActiveNavigationButton(props.productsElement?.name || '');
                setMobileMenuOpen(true);
              }}>
              <Hamburger size={24} />
            </button>
          </div>

          <ul className="hidden gap-4 lg:flex">
            <>
              {drawerElement && (
                <LazyOpenDrawerPreloadProps.Provider
                  value={{
                    name: drawerElement?.name ?? '',
                    title: drawerElement?.title ?? '',
                  }}>
                  <LazyOpenDrawerButton
                    key={drawerElement?.title}
                    setActiveDrawer={setActiveNavigationButton}
                    setOpen={setDesktopNavigationDrawerOpen}
                    activeDrawer={activeNavigationButton}
                    desktopNavigationDrawerOpen={desktopNavigationDrawerOpen}
                    title={drawerElement?.title}
                    name={drawerElement?.name}
                  />
                </LazyOpenDrawerPreloadProps.Provider>
              )}

              {props.newsElement && (
                <li
                  key={props?.newsElement?.title}
                  className={cn([
                    'cursor-pointer whitespace-nowrap hover:underline',
                    pathname.endsWith(props?.newsElement?.page?.slug || ' ') &&
                      !activeNavigationButton &&
                      'underline',
                  ])}>
                  <Link
                    href={`${props?.newsElement?.page?.slug?.toLowerCase()}`}>
                    {props?.newsElement?.title}
                  </Link>
                </li>
              )}

              {props?.campaignsElement && (
                <LazyOpenDrawerPreloadProps.Provider
                  value={{
                    name: props?.campaignsElement?.name ?? '',
                    title: props?.campaignsElement?.title ?? '',
                  }}>
                  <LazyOpenDrawerButton
                    key={props?.campaignsElement?.title}
                    setActiveDrawer={setActiveNavigationButton}
                    setOpen={setDesktopNavigationDrawerOpen}
                    activeDrawer={activeNavigationButton}
                    desktopNavigationDrawerOpen={desktopNavigationDrawerOpen}
                    title={props?.campaignsElement?.title}
                    name={props?.campaignsElement?.name}
                  />
                </LazyOpenDrawerPreloadProps.Provider>
              )}
              {sortedRemainingNavigationElements.map((navigation) => (
                <li
                  key={navigation?.title}
                  className={cn([
                    'cursor-pointer whitespace-nowrap hover:underline',
                    pathname.endsWith(navigation?.page?.slug || ' ') &&
                      !activeNavigationButton &&
                      'underline',
                  ])}>
                  <Link href={`${navigation?.page?.slug?.toLowerCase()}`}>
                    {navigation?.title}
                  </Link>
                </li>
              ))}
            </>
          </ul>
        </nav>
      </div>
      <LazyMenuDrawer
        open={desktopNavigationDrawerOpen}
        setOpen={setDesktopNavigationDrawerOpen}
        activeNavigationElement={activeNavigationElement ?? null}
      />
      <LazyMobileMenu
        open={mobileMenuOpen}
        setOpen={setMobileMenuOpen}
        activeNavigationElement={activeNavigationElement ?? null}
        newsElement={props.newsElement}
        campaignElement={props.campaignsElement}
        remainingNavigationElements={sortedRemainingNavigationElements}
      />
    </>
  );
}
