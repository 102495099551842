import { create } from 'zustand';

type MenuStore = {
  topBarHeight: number;
  setTopBarHeight: (value: number) => void;
  topBarVisible: boolean;
  setTopBarVisible: (value: boolean) => void;
  showSearchAndCampaignBar: boolean;
  setShowSearchAndCampaignBar: (value: boolean) => void;
  showSearchIcon: boolean;
  setShowSearchIcon: (value: boolean) => void;
  drawerIsOpen: boolean;
  setDrawerIsOpen: (value: boolean) => void;
};

export const useMenuStore = create<MenuStore>((set) => ({
  topBarHeight: 0,
  setTopBarHeight: (value) => set({ topBarHeight: value }),
  topBarVisible: true,
  setTopBarVisible: (value) => set({ topBarVisible: value }),
  showSearchAndCampaignBar: true,
  showSearchIcon: false,
  setShowSearchAndCampaignBar: (value) =>
    set({ showSearchAndCampaignBar: value }),
  setShowSearchIcon: (value) => set({ showSearchIcon: value }),
  drawerIsOpen: false,
  setDrawerIsOpen: (value) => set({ drawerIsOpen: value }),
}));
