import Image from 'next/image';
import type { CartItem } from '@business/gql/graphql';
import { cn } from '@ngg/storefront-utils';
import Link from '@ui/components/Link/Link';
import { locale } from '@utils/config';

function LineItem({ item: { imageKey, name, quantity } }: { item: CartItem }) {
  return (
    <>
      {imageKey && (
        <div className="relative block min-h-8 w-8 flex-shrink-0 bg-beige">
          <Image
            fill
            className="h-full w-full overflow-hidden object-contain p-1 mix-blend-multiply"
            src={`${process.env.NEXT_PUBLIC_NORCE_IMAGE_CDN}/${imageKey}?w=32&h=32&mode=max`}
            alt={name}
          />
        </div>
      )}
      <span className="truncate">
        {quantity} x {name}
      </span>
    </>
  );
}

export default function UnderlyingItems({
  items,
  className,
}: {
  items?: (Omit<CartItem, 'priceListId'> | null)[] | null;
  className?: string;
}) {
  if (!items?.length) return null;
  return (
    <ul className={cn('flex flex-col gap-2 overflow-hidden', className)}>
      {items
        .filter((item): item is CartItem => Boolean(item))
        .map((item) => (
          <li
            key={item.id}
            className="flex items-center gap-2 overflow-hidden text-sm">
            {item.extras?.product_url ? (
              <Link href={item.extras.product_url} locale={locale}>
                <LineItem item={item} />
              </Link>
            ) : (
              <LineItem item={item} />
            )}
          </li>
        ))}
    </ul>
  );
}
