import { create } from 'zustand';

type DeliveryStore = {
  waitingForDeliveryOptionToBeSet: boolean;
  setWaitingForDeliveryOptionToBeSet: (focus: boolean) => void;
  setZipCode: (focus: string) => void;
  zipCode: string;
};

export const useDeliveryStore = create<DeliveryStore>((set) => ({
  waitingForDeliveryOptionToBeSet: false,
  setWaitingForDeliveryOptionToBeSet: (focus: boolean) =>
    set({ waitingForDeliveryOptionToBeSet: focus }),
    setZipCode: (zipCode: string) => set({ zipCode }),
    zipCode: '',
}));
