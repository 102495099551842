'use client';

import { ContentfulImage as ContentfulImageComponent } from '@ui/components/Contentful';
import Link from '@ui/components/Link/Link';
import { Asset, Maybe } from '@business/gql/graphql';

type Props = {
  desktopImage?: Maybe<Omit<Asset, 'contentfulMetadata' | 'sys'>>;
  mobileImage?: Maybe<Omit<Asset, 'contentfulMetadata' | 'sys'>>;
};

export default function PageHeaderLogo(props: Props) {
  const { desktopImage, mobileImage } = props;

  return (
    <Link href="/">
      <ContentfulImageComponent
        className="mx-auto h-10 w-full max-w-xs object-contain"
        desktopImage={desktopImage}
        mobileImage={mobileImage}
        priority
        sizes="256px"
      />
    </Link>
  );
}
